export function isOutboundLink(link, currentHostname) {
  return link.href && link.host && link.host !== currentHostname;
}
export function shouldFollowLink(event, link) {
  if (event.defaultPrevented)
    return false;
  const downloadable = link.hasAttribute("download");
  const targetsCurrentWindow = !link.target || Boolean(link.target.match(/^_(self|parent|top)$/i));
  const isRegularClick = !(event.ctrlKey || event.metaKey || event.shiftKey) && event.type === "click";
  return targetsCurrentWindow && isRegularClick && !downloadable;
}
export function openLink(link) {
  const href = link.getAttribute("href") || "";
  const target = link.getAttribute("target") || "_self";
  const rel = link.getAttribute("rel") || "";
  const windowFeatures = rel.split(" ").join(",");
  window.open(href, target, windowFeatures);
}
