import { isOutboundLink, openLink, shouldFollowLink } from "./utils.mjs";
export function useAutoOutboundTracking(plausible, initOptions) {
  const options = { ...initOptions };
  const tracked = /* @__PURE__ */ new Set();
  function setEventOptions(newOptions) {
    Object.assign(options, newOptions);
  }
  function handleLinkClickEvent(event) {
    const location2 = window.location;
    if (event.type === "auxclick" && event.button !== 1 || !isOutboundLink(this, location2))
      return;
    const href = this.getAttribute("href");
    let followedLink = false;
    const followLink = () => {
      if (!followedLink) {
        followedLink = true;
        openLink(this);
      }
    };
    const props = { url: href };
    if (shouldFollowLink(event, this)) {
      plausible.trackEvent("Outbound Link: Click", { props, callback: followLink });
      setTimeout(followLink, 1e3);
      event.preventDefault();
    } else {
      plausible.trackEvent("Outbound Link: Click", { props });
    }
  }
  function addNode(node) {
    if (node instanceof HTMLAnchorElement) {
      if (node.host !== location.host) {
        node.addEventListener("click", handleLinkClickEvent);
        node.addEventListener("auxclick", handleLinkClickEvent);
        tracked.add(node);
      }
    } else if ("querySelectorAll" in node) {
      node.querySelectorAll("a").forEach(addNode);
    }
  }
  function removeNode(node) {
    if (node instanceof HTMLAnchorElement) {
      node.removeEventListener("click", handleLinkClickEvent);
      node.removeEventListener("auxclick", handleLinkClickEvent);
      tracked.delete(node);
    } else if ("querySelectorAll" in node) {
      node.querySelectorAll("a").forEach(removeNode);
    }
  }
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      if (mutation.type === "attributes") {
        removeNode(mutation.target);
        addNode(mutation.target);
      } else if (mutation.type === "childList") {
        mutation.addedNodes.forEach(addNode);
        mutation.removedNodes.forEach(removeNode);
      }
    });
  });
  function install() {
    const targetNode = document;
    const observerInit = {
      subtree: true,
      childList: true,
      attributes: true,
      attributeFilter: ["href"]
    };
    targetNode.querySelectorAll("a").forEach(addNode);
    observer.observe(targetNode, observerInit);
  }
  function cleanup() {
    tracked.forEach((a) => {
      a.removeEventListener("click", handleLinkClickEvent);
      a.removeEventListener("auxclick", handleLinkClickEvent);
    });
    tracked.clear();
    observer.disconnect();
  }
  ;
  return {
    install,
    cleanup,
    setEventOptions
  };
}
