import { useNuxtApp, useRuntimeConfig } from "#imports";
import { useAutoOutboundTracking } from "@barbapapazes/plausible-tracker/extensions/auto-outbound-tracking";
import { defineNuxtPlugin } from "nuxt/app";
export default defineNuxtPlugin({
  name: "plausible:auto-outbound-tracking",
  setup() {
    const options = useRuntimeConfig().public.plausible;
    const { $plausible } = useNuxtApp();
    if (options.enabled && options.autoOutboundTracking) {
      useAutoOutboundTracking($plausible).install();
    }
  }
});
